import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        // 官网首页
        path: '/',
        name: 'index',
        component: () =>
            import ('../views/index/index'),
    },
    {
        path: '/website',
        name: 'website',
        component: () =>
            import ('../views/wzjs/website'),
    },
    {
        path: '/works',
        component: () =>
            import ('../views/zuopin/works'),
    },
    {
        path: '/moban',
        name: 'moban',
        component: () =>
            import ('../views/moban/moban'),
    },
    {
        path: '/trends',
        name: 'trends',
        component: () =>
            import ('../views/xwdt/trends'),
    },
    {
        path: '/aboutuser',
        name: 'aboutuser',
        component: () =>
            import ('../views/gywm/aboutuser'),
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        component: () =>
            import ('../views/lxwm/contactUs'),
    },
    // 制造电器
    {
        path: '/zz_index1',
        name: 'zz_index1',
        component: () =>
            import ('../views/index/zz_index1'),
    }, {
        path: '/zz_aboutuser1',
        name: 'zz_aboutuser1',
        component: () =>
            import ('../views/gywm/zz_aboutuser1'),
    }, {
        path: '/zz_works',
        name: 'zz_works',
        component: () =>
            import ('../views/zuopin/zz_works'),
    }, {
        path: '/zz_contactUs1',
        name: 'zz_contactUs1',
        component: () =>
            import ('../views/lxwm/zz_contactUs1'),
    }, {
        path: '/zz_trends',
        name: 'zz_trends',
        component: () =>
            import ('../views/xwdt/zz_trends'),
    },
    // 制造商厨
    {
        path: '/zz_index2',
        name: 'zz_index2',
        component: () =>
            import ('../views/index/zz_index2'),
    },
    {
        path: '/zz_programme2',
        name: 'zz_programme2',
        component: () =>
            import ('../views/anli/zz_programme2'),
    },
    {
        path: '/zz_product2',
        name: 'zz_product2',
        component: () =>
            import ('../views/zuopin/zz_product2'),
    }, {
        path: '/zz_trends2',
        name: 'zz_trends2',
        component: () =>
            import ('../views/xwdt/zz_trends2'),
    }, {
        path: '/zz_contactUs2',
        name: 'zz_contactUs2',
        component: () =>
            import ('../views/lxwm/zz_contactUs2'),
    }, {
        path: '/zz_aboutuser2',
        name: 'zz_aboutuser2',
        component: () =>
            import ('../views/gywm/zz_aboutuser2'),
    },
    // 科技类
    {
        path: '/kj_index',
        name: 'kj_index',
        component: () =>
            import ('../views/index/kj_index'),
    }, {
        path: '/kj_works',
        name: 'kj_works',
        component: () =>
            import ('../views/zuopin/kj_works'),
    }, {
        path: '/kj_service',
        name: 'kj_service',
        component: () =>
            import ('../views/xwdt/kj_service'),
    }, {
        path: '/kj_profile',
        name: 'kj_profile',
        component: () =>
            import ('../views/gywm/kj_profile'),
    }, {
        path: '/kj_idea',
        name: 'kj_idea',
        component: () =>
            import ('../views/anli/kj_idea'),
    }, {
        path: '/kj_contact',
        name: 'kj_contact',
        component: () =>
            import ('../views/lxwm/kj_contact'),
    },
    // 医疗类
    {
        path: '/yl_index',
        name: 'yl_index',
        component: () =>
            import ('../views/index/yl_index'),
    },
    // 休闲类
    {
        path: '/xx_index',
        name: 'xx_index',
        component: () =>
            import ('../views/index/xx_index'),
    }, {
        path: '/xx_business',
        name: 'xx_business',
        component: () =>
            import ('../views/zuopin/xx_business'),
    }, {
        path: '/xx_region',
        name: 'xx_region',
        component: () =>
            import ('../views/anli/xx_region'),
    }, {
        path: '/xx_trends',
        name: 'xx_trends',
        component: () =>
            import ('../views/xwdt/xx_trends'),
    }, {
        path: '/xx_aboutuser',
        name: 'xx_aboutuser',
        component: () =>
            import ('../views/gywm/xx_aboutuser'),
    }, {
        path: '/xx_contactUs',
        name: 'xx_contactUs',
        component: () =>
            import ('../views/lxwm/xx_contactUs'),
    },
    // 装修类
    {
        path: '/zx_index',
        name: 'zx_index',
        component: () =>
            import ('../views/index/zx_index'),
    },
    {
        path: '/zx_cpzx',
        name: 'zx_cpzx',
        component: () =>
            import ('../views/zuopin/zx_cpzx'),
    },
    {
        path: '/zx_gdAl',
        name: 'zx_gdAl',
        component: () =>
            import ('../views/anli/zx_gdAl'),
    },
    {
        path: '/zx_news',
        name: 'zx_news',
        component: () =>
            import ('../views/xwdt/zx_news'),
    },
    // 教育类
    {
        path: '/jy_index',
        name: 'jy_index',
        component: () =>
            import ('../views/index/jy_index'),
    }, {
        path: '/jy_course',
        name: 'jy_course',
        component: () =>
            import ('../views/anli/jy_course'),
    }, {
        path: '/jy_equip',
        name: 'jy_equip',
        component: () =>
            import ('../views/zuopin/jy_equip'),
    }, {
        path: '/jy_news',
        name: 'jy_news',
        component: () =>
            import ('../views/xwdt/jy_news'),
    }, {
        path: '/jy_coach',
        name: 'jy_coach',
        component: () =>
            import ('../views/moban/jy_coach'),
    }, {
        path: '/jy_trip',
        name: 'jy_trip',
        component: () =>
            import ('../views/wzjs/jy_trip'),
    }, {
        path: '/jy_aboutuser',
        name: 'jy_aboutuser',
        component: () =>
            import ('../views/gywm/jy_aboutuser'),
    },
    // 农业类
    {
        path: '/ny_index',
        name: 'ny_index',
        component: () =>
            import ('../views/index/ny_index'),
    },
    {
        path: '/ny_product',
        name: 'ny_product',
        component: () =>
            import ('../views/zuopin/ny_product'),
    },
    {
        path: '/ny_anli',
        name: 'ny_anli',
        component: () =>
            import ('../views/anli/ny_anli'),
    },
    {
        path: '/ny_news',
        name: 'ny_news',
        component: () =>
            import ('../views/xwdt/ny_news'),
    }, {
        path: '/ny_aboutuser',
        name: 'ny_aboutuser',
        component: () =>
            import ('../views/gywm/ny_aboutuser'),
    }, {
        path: '/ny_contactUs',
        name: 'ny_contactUs',
        component: () =>
            import ('../views/lxwm/ny_contactUs'),
    },
    // 旅游类
    {
        path: '/ly_index',
        name: 'ly_index',
        component: () =>
            import ('../views/index/ly_index'),
    },
    {
        path: '/ly_give',
        name: 'ly_give',
        component: () =>
            import ('../views/anli/ly_give'),
    },
    {
        path: '/ly_booking',
        name: 'ly_booking',
        component: () =>
            import ('../views/lxwm/ly_booking'),
    }, {
        path: '/ly_strategy',
        name: 'ly_strategy',
        component: () =>
            import ('../views/gywm/ly_strategy'),
    }, {
        path: '/ly_introduce',
        name: 'ly_introduce',
        component: () =>
            import ('../views/wzjs/ly_introduce'),
    }, {
        path: '/ly_news',
        name: 'ly_news',
        component: () =>
            import ('../views/xwdt/ly_news'),
    },
    // 环保类
    {
        path: '/hb_index',
        name: 'hb_index',
        component: () =>
            import ('../views/index/hb_index'),
    }, {
        path: '/hb_service',
        name: 'hb_service',
        component: () =>
            import ('../views/zuopin/hb_service'),
    }, {
        path: '/hb_anli',
        name: 'hb_anli',
        component: () =>
            import ('../views/anli/hb_anli'),
    }, {
        path: '/hb_news',
        name: 'hb_news',
        component: () =>
            import ('../views/xwdt/hb_news'),
    }, {
        path: '/hb_about',
        name: 'hb_about',
        component: () =>
            import ('../views/gywm/hb_about'),
    },
    // 3d类
    {
        path: '/real3d',
        name: 'real3d',
        component: () =>
            import ('../views/index/real3d'),
    }, {
        path: '/works3d',
        name: 'works3d',
        component: () =>
            import ('../views/zuopin/works3d'),
    }, {
        path: '/case3d',
        name: 'case3d',
        component: () =>
            import ('../views/anli/case3d'),
    }, {
        path: '/news3d',
        name: 'news3d',
        component: () =>
            import ('../views/xwdt/news3d'),
    }, {
        path: '/aboutuser3d',
        name: 'aboutuser3d',
        component: () =>
            import ('../views/gywm/aboutuser3d'),
    },
    // 科技类-科幻
    {
        path: '/kh_science',
        name: 'kh_science',
        component: () =>
            import ('../views/index/kh_science'),
    }, {
        path: '/kh_structure',
        name: 'kh_structure',
        component: () =>
            import ('../views/moban/kh_structure'),
    }, {
        path: '/kh_expert',
        name: 'kh_expert',
        component: () =>
            import ('../views/anli/kh_expert'),
    }, {
        path: '/kh_news',
        name: 'kh_news',
        component: () =>
            import ('../views/xwdt/kh_news'),
    }, {
        path: '/kh_gain',
        name: 'kh_gain',
        component: () =>
            import ('../views/zuopin/kh_gain'),
    },
    // 餐饮类 黄记煌
    {
        path: '/cy_index',
        name: 'cy_index',
        component: () =>
            import ('../views/index/cy_index'),
    },
    {
        path: '/cy_store',
        name: 'cy_store',
        component: () =>
            import ('../views/zuopin/cy_store'),
    },
    {
        path: '/cy_brand',
        name: 'cy_brand',
        component: () =>
            import ('../views/anli/cy_brand'),
    }, {
        path: '/cy_news',
        name: 'cy_news',
        component: () =>
            import ('../views/xwdt/cy_news'),
    }, {
        path: '/cy_aboutuser',
        name: 'cy_aboutuser',
        component: () =>
            import ('../views/gywm/cy_aboutuser'),
    }, {
        path: '/cy_contactUs',
        name: 'cy_contactUs',
        component: () =>
            import ('../views/lxwm/cy_contactUs'),
    }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((to, form, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    } else {
        let name = sessionStorage.getItem(Vue.prototype.changeData() + 'Name')
        if (name != 'undefined') {
            if (name) {
                document.title = name
            } else {
                document.title = '迅帆科技'
            }
        } else {
            document.title = '迅帆科技'
        }
    }
    next()
})

export default router