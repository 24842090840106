<template>
  <div class="index-footer" v-if="indexName != 'ylkj'">
    <div class="pc-footer">
      <div class="footer-1 container">
        <div class="footer-1-title">
          <span>友情连接 / LINK</span>
        </div>
        <div class="footer-1-nr">
          <a
            :href="item.link"
            target="_blank"
            v-for="(item, idx) in urlList"
            :key="idx"
            >{{ item.name }}</a
          >
        </div>
        <div class="footer-1-r">
          <div
            @mouseover="ewmShow = true"
            @mouseleave="ewmShow = false"
            style="margin-right:10px"
          >
            <el-button class="weixing"
              ><i class="wx-icon"></i>微信二维码</el-button
            >
          </div>
          <el-button class="fanhui" @click="fanhuiTop"
            ><i class="wx-icon fanhui-ico"></i>返回顶部</el-button
          >
          <div class="ewm" v-if="ewmShow">
            <img
              :src="currentData.wxewm"
              alt="微信二维码"
            />
          </div>
        </div>
      </div>
      <div class="footer-2">
        <div class="container" v-html="currentData.pc_footers"></div>
      </div>
    </div>
    <div class="mobile_footer">
      <div class="footer-2-nav">
        <div class="footer-2-title">服务项目</div>
        <div class="footer-2-text">
          <a
            :href="item.link"
            target="_blank"
            v-for="(item, idx) in urlList"
            :key="idx"
            >{{ item.name }}</a
          >
        </div>
      </div>
      <div class="footer-2-nav">
        <div class="footer-2-title">联系我们</div>
        <div class="footer-2-text">
          <div>
            在线电话：<span>{{ currentData.phone }}</span
            >（微信同号）
          </div>
          <div>服务地区：广州、深圳、西安、佛山、东莞、惠州、北京、上海、武汉…</div>
        </div>
      </div>
      <div class="footer-2-bq" v-html="currentData.sj_footers"></div>
    </div>
  </div>
</template>
<script>
import { getFriendsList } from '@/api/api'
export default {
  props: {
    currentData: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      urlList: [],
      ewmShow: false,
      indexName:''
    }
  },
  methods: {
    fanhuiTop () {
      $('html,body').animate({ scrollTop: 0 }, 800)
      this.$emit('onFanhuiTop')
    },
    getData () {
      this.indexName = this.changeData()
      getFriendsList().then(res => {
        if (res.data.code == 1) {
          this.urlList = res.data.data
        }
      })
    }
  },
  mounted () {
    this.getData()
  },
  computed: {}
}
</script>
<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.index-footer {
  width: 100%;
  .pc-footer {
    background-color: #1e1e1e;
    min-width: 1200px;
    .footer-1 {
      padding: 25px 0 25px;
      .footer-1-title {
        padding: 15px 0 15px;
        display: flex;
        justify-content: space-between;
        span {
          color: #fff;
          font-weight: normal;
          font-size: 18px;
        }
        a {
          width: 130px;
          height: 32px;
          color: #fff;
          background-color: #e62022;
          font-size: 14px;
          border-radius: 20px;
        }
        a:hover {
          background-color: #c8011c !important;
        }
      }
      .footer-1-nr {
        line-height: 1.5;
        a {
          font-size: 14px;
          color: #868686;
          text-decoration: none;
          margin-right: 10px;
        }
      }
      .footer-1-r {
        position: relative;
        padding-top: 15px;
        display: flex;
        justify-content: flex-end;
        button {
          width: 120px;
          color: #fff;
          transition: 0.3s linear;
        }
        button:hover {
          opacity: 0.8;
        }
        .weixing,
        .fanhui {
          background-color: #04c38e;
          border-color: #04c38e;
          .wx-icon {
            vertical-align: middle;
            margin-right: 7px;
            display: inline-block;
            background: url(~@/assets/image/other/foot-wx-ico.png)
              no-repeat 0px 8px;
            height: 37px;
            padding-left: 29px;
          }
        }
        .fanhui {
          background-color: #0856ab;
          border-color: #0856ab;
          .fanhui-ico {
            background: url(~@/assets/image/other/foot-db-ico.png)
              no-repeat 0px 8px !important;
          }
        }
        .ewm {
          position: absolute;
          bottom: 48px;
          right: 130px;
          z-index: 10;
        }
      }
    }
    .footer-2 {
      width: 100%;
      padding: 30px 0;
      background-color: #0a0a0a;
      text-align: center;
      font-size: 14px;
      line-height: 1.8;
      color: #b2b2b2;
    }
  }
  .mobile_footer {
    width: calc(100% - 2rem);
    padding: 1rem;
    padding-bottom: 3.6rem;
    background-color: #323232;
    text-align: left;
    line-height: 1.8;
    color: #b2b2b2;
    font-size: 0.8rem;
    display: none;
    .footer-2-nav {
      .footer-2-title {
        padding: 0.2rem 0;
        color: #fff;
        font-weight: bolder;
        font-size: 0.8rem;
        text-align: left;
      }
      .footer-2-text {
        font-size: 0.7rem;
        a {
          font-size: 0.6rem;
          font-weight: 600;
          margin-right: 0.2rem;
        }
        span {
          font-size: 0.8rem;
          font-weight: 600;
          color: #fff;
        }
      }
    }
    .footer-2-bq {
      text-align: center;
      font-size: 0.7rem;
      margin-top: 0.5rem;
      /deep/img {
        width: 1rem !important;
        vertical-align: sub !important;
      }
    }
  }
}
/deep/.el-button {
  padding: 0px 5px;
}
</style>
