import instance from './http'

export function getBasicMsg(i) {
    return instance({
        url: '/addons/singlewebsite/config/base?project=' + i,
        method: 'get',
    })
}

export function getMenuData(i) {
    return instance({
        url: '/addons/singlewebsite/menu/menuList?project=' + i,
        method: 'get',
    })
}

export function getMenuBanners() {
    return instance({
        url: '/addons/singlewebsite/banner/menuBanners?menu_id=1',
        method: 'get',
    })
}

// 获取模板分页
export function getMobanList(i, x) {
    return instance({
        url: '/addons/singlewebsite/tpls/list?page=' + i + '&client_ids=' + x + '&pagesize=8&category_id=""',
        method: 'get',
    })
}

export function getMobanData(i, x) {
    return instance({
        url: '/addons/singlewebsite/tpls/list?page=' + i + '&industry_id=' + x + '&pagesize=8&category_id=""',
        method: 'get',
    })
}
// 搜索模板
export function searchMoban(i) {
    return instance({
        url: '/addons/singlewebsite/tpls/list?page=1&industry_id=&pagesize=8&category_id=""&title=' + i,
        method: 'get',
    })
}

// 获取模板行业和支持端接口
export function getIndustryList(i) {
    return instance({
        url: '/addons/singlewebsite/tpls/getindustryandclients?client_ids=' + i,
        method: 'get',
    })
}

// 获取精品案例 
export function getCaseList(i, x, d) {
    return instance({
        url: '/addons/singlewebsite/cases/list?page=' + i + '&pagesize=8&type_id=' + x + '&project=' + d,
        method: 'get',
    })
}

export function getJyCaseList(i, x, d) {
    return instance({
        url: '/addons/singlewebsite/cases/list?page=' + i + '&pagesize=' + x + '&type_id=&project=' + d,
        method: 'get',
    })
}

export function getZzCaseList(data) {
    return instance({
        url: '/addons/singlewebsite/cases/list',
        method: 'get',
        params: data
    })
}

export function getCaseTypes(i) {
    return instance({
        url: '/addons/singlewebsite/cases/types?project=' + i,
        method: 'get',
    })
}

export function getCaseDetail(x, d) {
    return instance({
        url: '/addons/singlewebsite/cases/detail?id=' + x + '&project=' + d,
        method: 'get',
    })
}

// 获取新闻
export function getXwData(i) {
    return instance({
        url: '/addons/singlewebsite/news/types?project=' + i,
        method: 'get',
    })
}

// 获取全部新闻
export function getAllXwData(i, x, d) {
    return instance({
        url: '/addons/singlewebsite/news/list?page=' + i + '&pagesize=6&category_id=' + x + '&project=' + d,
        method: 'get',
    })
}

// 获取新闻类型
export function getXwTypeData(i, x) {
    return instance({
        url: '/addons/singlewebsite/news/types?new_id=' + i + '&project=' + x + '&operate=type',
        method: 'get',
    })
}

// 获取单个新闻的详情
export function getXwxqData(i) {
    return instance({
        url: '/addons/singlewebsite/news/detail?new_id=' + i,
        method: 'get',
    })
}

// 获取留言接口
export function submitToData(params) {
    return instance({
        url: '/addons/singlewebsite/feek/add',
        method: 'post',
        data: params
    })
}

// 获取页面配置信息接口
export function getPageMsg(i) {
    return instance({
        url: '/addons/singlewebsite/pageinfo/config?id=' + i,
        method: 'get',
    })
}

// 获取服务和流程接口
export function getServersList() {
    return instance({
        url: '/addons/singlewebsite/servers/list',
        method: 'get',
    })
}

// 获取合作伙伴
export function getFriendsList() {
    return instance({
        url: '/addons/singlewebsite/friends/list',
        method: 'get',
    })
}

// 获取产品数据
export function getProductList(i, x, d) {
    return instance({
        url: '/addons/singlewebsite/products/list?page=' + i + '&pagesize=8&type_id=' + x + '&project=' + d,
        method: 'get',
    })
}

export function getAllList(data) {
    return instance({
        url: '/addons/singlewebsite/products/list',
        method: 'get',
        params: data
    })
}

export function getAlDetail(x, d) {
    return instance({
        url: '/addons/singlewebsite/products/detail?id=' + x + '&project=' + d,
        method: 'get',
    })
}

export function getProductTypes(i) {
    return instance({
        url: '/addons/singlewebsite/products/types?project=' + i,
        method: 'get',
    })
}