<template>
  <div id="app">
    <Head
      :style="{ top: navShow ? '-80px' : '0' }"
      :class="headBg ? 'headNavUp' : ''"
      :currentData="currentData"
      v-if="rouertName != 'yl_index'"
    ></Head>
    <keep-alive>
      <router-view />
    </keep-alive>
    <Footer :currentData="currentData" v-if="rouertName != 'yl_index' && rouertName != 'website' && rouertName != 'ly_introduce'"></Footer>
    <Quickly
      v-if="rouertName != 'yl_index' && rouertName != 'ly_introduce'"
      :currentData="currentData"
    ></Quickly>
  </div>
</template>
<script>
import Head from '@/common/head/index-head.vue'
import { getBasicMsg } from '@/api/api'
import Footer from '@/common/footer/index-footer.vue'
import Quickly from '@/common/quickly/quickly.vue'
export default {
  name: 'app',
  components: {
    Head,
    Footer,
    Quickly
  },
  data () {
    return {
      top: '',
      navShow: false,
      headBg: false,
      current_div: true,
      rouertName: '',
      currentData: {}
    }
  },
  watch: {
    top (newValue, oldValue) {
      if (newValue == 0) {
        this.navShow = false
      }
      if (newValue > 80) {
        this.headBg = true
        if (newValue > oldValue) {
          this.navShow = true
          // console.log('向下滚动')
        } else {
          this.navShow = false
          // console.log('向上滚动')
        }
      } else {
        this.headBg = false
      }
    },
    $route (to, from) {
      this.rouertName = to.name
      $('html,body').animate({ scrollTop: 0 }, 800)
      this.$emit('onFanhuiTop')
    }
  },
  created () {
    let that = this
    getBasicMsg(that.changeData()).then(res => {
      if (res.data.code == 1) {
        that.handleScroll()
        that.currentData = res.data.data
        sessionStorage.setItem(this.changeData() + 'project',res.data.data.project_id)
      }
    })
  },
  methods: {
    handleScroll () {
      window.addEventListener('scroll', () => {
        this.top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset
      })
      this.screenWidth = document.body.clientWidth
      window.onresize = () => {
        //屏幕尺寸变化
        return (() => {
          this.screenWidth = document.body.clientWidth
        })()
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="less">
@import url('./assets/css/account.css');
@import url('./assets/css/iconFont.css');
@import url('./assets/css/animation.less');
@import url('~@/assets/css/media.less');
@import url('~@/assets/css/index/real3d.less');
@import url('~@/assets/css/index/zx_media.less');
@import url('~@/assets/css/index/zz_media.less');
@import url('~@/assets/css/index/kj_media.less');
@import url('~@/assets/css/index/xx_media.less');
@import url('~@/assets/css/index/jy_media.less');
@import url('~@/assets/css/index/ny_media.less');
@import url('~@/assets/css/index/ly_media.less');
@import url('~@/assets/css/index/hb_media.less');
@import url('~@/assets/css/index/cy_media.less');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // user-select: none;
}
.headNavUp {
  background-color: rgba(0, 0, 0, 0.6);
}
// ::-webkit-scrollbar {
//   width: 0 !important;
// }
// ::-webkit-scrollbar {
//   width: 0 !important;
//   height: 0;
// }
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
