<template>
  <div class="all_head" v-if="headText.length != 0 || indexName != 'ylkj'">
    <div class="all_head_body">
      <div class="all_head_body_logo">
        <a>
          <img
            v-if="currentData.网站logo"
            :src="currentData.网站logo"
            style="height: 100%;"
            alt="网站建设,做网站,网站制作公司,建站公司,网站制作"
          />
          <img
            v-else
            src="@/assets/image/logo.png"
            style="height: 100%;"
            alt="网站建设,做网站,网站制作公司,建站公司,网站制作"
          />
        </a>
      </div>
      <ul class="all_head_body_text">
        <el-menu
          :default-active="$route.path"
          class="el-menu-demo"
          text-color="#fff"
          router
          menu-trigger="hover"
          mode="horizontal"
          active-text-color="#409eff"
          background-color="rgba(0,0,0,0)"
        >
          <el-menu-item
            v-for="(item, idx) in headText"
            :key="idx"
            :index="item.tpl"
            @click="toLink(item)"
            ><div
              @mouseenter="mouseenterDiv(item)"
              @mouseleave="div_show = false"
            >
              {{ item.name
              }}<i
                :class="div_show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
                v-if="item.childlist != 0"
              ></i>
            </div>
            <div
              class="hoveDiv"
              @mouseenter="div_show = true"
              @mouseleave="div_show = false"
              v-if="nav_show && item.tpl == '/website'"
            >
              <div class="hoveDiv_nav" v-if="div_show">
                <a
                  v-for="el in item.childlist"
                  :key="el.id"
                  :href="'#' + el.tpl"
                  >{{ el.name }}</a
                >
              </div>
            </div></el-menu-item
          >
        </el-menu>
      </ul>
      <div class="all_head_body_tel">
        <div class="tel_logo">
          <img src="@/assets/image/svg/phone.svg" alt="" />
        </div>
        <div class="tel_phone">
          <div>{{ currentData.kf_phone }} <span>（咨询热线）</span></div>
          <div>{{ currentData.phone }} <span>（微信同号）</span></div>
        </div>
      </div>
    </div>
    <div class="all_head_body2">
      <div class="body2_nav">
        <div class="all_head_body_logo">
          <a>
            <img
              v-if="currentData.手机端logo"
              :src="currentData.手机端logo"
              style="width:100%;height: 100%;"
              alt="网站建设,做网站,网站制作公司,建站公司,网站制作"
            />
            <img
              v-else
              src="@/assets/image/logo1.png"
              style="width:100%;height: 100%;"
              alt="网站建设,做网站,网站制作公司,建站公司,网站制作"
            />
          </a>
        </div>
        <div
          class="nav_sp_nav"
          :class="open_show ? 'sp_nav_se' : ''"
          @click="open_show = !open_show"
        >
          <span v-for="el in 3" :key="el"></span>
        </div>
      </div>
      <div class="body2_list" :style="{ right: open_show ? '-2px' : '-100%' }">
        <ul>
          <li
            v-for="(item, idx) in headText"
            :key="idx"
            @click="toLink(item, 1)"
          >
            {{ item.name }}
            <i v-if="item.childlist.length == 0"></i>
            <i
              v-else
              :style="{
                borderWidth: div_show2 ? '0 0 1px 1px' : '0 1px 1px 0'
              }"
            ></i>
            <div
              class="current_div"
              :style="{ height: div_show2 ? '1.8rem' : '0px' }"
              v-show="item.childlist.length != 0"
            >
              <div
                v-for="el in item.childlist"
                :key="el.id"
                :style="{ opacity: div_show2 ? 1 : 0 }"
                @click="toLink(el, 2)"
              >
                {{ el.name }}
              </div>
            </div>
          </li>
        </ul>
        <div class="list_menu_fot">
          <div>迅帆科技真诚期待您的来电</div>
          <a href="tel:020-39259989">
            <span>400-118-1615</span>
          </a>
        </div>
      </div>
      <div
        class="body2_list_bg"
        v-if="open_show"
        @click="open_show = false"
      ></div>
    </div>
  </div>
</template>
<script>
import { getMenuData } from '@/api/api'
export default {
  props: {
    currentData: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      head_top: '',
      headText: [],
      open_show: false,
      div_show: false,
      div_show2: false,
      nav_show: true,
      hove_show: '',
      currentpath: '',
      indexName:''
    }
  },
  watch: {
    head_top (newValue, oldValue) {
      if (newValue > 80) {
        if (newValue > oldValue) {
          this.nav_show = false
        } else {
          this.nav_show = true
        }
      }
    },
    $route (to, from) {
      this.getHeadList(to.path)
    }
  },
  methods: {
    getHeadList (i) {
      this.indexName = this.changeData()
      getMenuData(this.changeData()).then(res => {
        if (res.data.code == 1) {
          this.headText = res.data.data
          if (i) {
            res.data.data.forEach(el => {
              if (el.tpl == i) {
                console.log(el.id);
                sessionStorage.setItem(this.changeData() + 'id', el.id)
              }
            })
          }
          this.handleScroll()
        }
      })
    },
    toLink (e, i) {
      if (i == 1) {
        if (e.childlist.length == 0) {
          this.open_show = false
          this.$router.push({ path: e.tpl })
        } else {
          this.div_show2 = !this.div_show2
        }
      }
      if (i == 2) {
        this.open_show = false
        this.$router.push({ path: e.tpl })
      }
      console.log(e.id);
      sessionStorage.setItem(this.changeData() + 'id', e.id)
    },
    mouseenterDiv (e) {
      if (e.childlist.length) {
        this.div_show = true
      } else {
        this.div_show = false
      }
    },
    handleScroll () {
      window.addEventListener('scroll', () => {
        this.head_top =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset
      })
    }
  },
  mounted () {
    this.getHeadList()
  },
}
</script>
<style lang="less" scoped>
.all_head {
  display: block;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  transition: 0.3s linear;
  box-sizing: border-box;
  color: #fff;
  .all_head_body,
  .all_head_body2 {
    width: 86%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1600px;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    .all_head_body_logo,
    .all_head_body_text,
    .all_head_body_tel {
      height: 100%;
      display: flex;
      align-items: center;
      li {
        height: 100%;
        .ceil_togo {
          display: flex;
          align-items: center;
          color: #fff;
          padding: 0 10px;
          font-size: 15px;
          height: 100%;
        }
        .on {
          border-bottom: 2px solid #fff;
          box-sizing: border-box;
        }
      }
      .tel_logo {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #fff;
        box-sizing: border-box;
        border-radius: 50%;
        margin-right: 10px;
        img {
          width: 32px;
          height: 32px;
        }
      }
      .tel_phone {
        height: 42px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span {
          font-size: 12px;
        }
      }
    }
    .el-menu {
      height: 100%;
    }
    .el-menu--horizontal > .el-menu-item.is-active {
      border-bottom: 2px solid #fff !important;
      color: #fff !important;
      box-sizing: border-box;
    }
    .el-menu-item {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }
    .el-menu--horizontal > .el-menu-item {
      line-height: 80px;
    }
    /deep/.el-menu--horizontal > .el-submenu .el-submenu__title {
      height: 100%;
      line-height: 80px;
      border-bottom-color: transparent;
      background-color: rgba(0, 0, 0, 0) !important;
      i {
        color: #fff;
      }
    }
    .el-menu-demo li {
      position: relative;
      i {
        margin-right: 0 !important;
        font-size: 12px !important;
        width: auto !important;
        margin-left: 5px;
        color: #fff;
      }
    }
    .hoveDiv {
      min-height: 5px;
      .hoveDiv_nav {
        position: absolute;
        width: 150px;
        top: 85px;
        left: 0;
        background-color: #ddd;
        line-height: 1;
        display: flex;
        flex-direction: column;
      }
      a {
        cursor: pointer;
        padding: 15px;
        color: #333;
        border-top: 1px solid #fff;
      }
      a:nth-child(1) {
        border-top: 0;
      }
      a:hover {
        color: #4242f3;
      }
    }
    .all_head_body_logo {
      a {
        height: 45px;
      }
    }
  }
  .all_head_body2 {
    display: none;
    height: 2.6rem;
    background-color: #fff;
    min-width: 100%;
    .body2_nav {
      width: 96%;
      margin: 0 auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .all_head_body_logo {
        a {
          max-width: 6rem;
          height: 2rem;
        }
      }
      .nav_sp_nav {
        width: 1.4rem;
        position: relative;
        cursor: pointer;
        height: 0.8rem;
        span {
          display: block;
          background: #231815;
          width: 1rem;
          height: 0.1rem;
          position: absolute;
          left: 0.2rem;
          transition: all ease 0.35s;
        }
        span:nth-of-type(1) {
          top: 0px;
        }
        span:nth-of-type(2) {
          top: 0.4rem;
        }
        span:nth-of-type(3) {
          top: 0.75rem;
        }
      }
      .sp_nav_se {
        span:nth-of-type(1) {
          top: 0.4rem;
          transform: rotate(45deg);
        }
        span:nth-of-type(2) {
          width: 0;
        }
        span:nth-of-type(3) {
          top: 0.4rem;
          transform: rotate(-45deg);
        }
      }
    }
    .body2_list {
      background: #fff;
      position: fixed;
      top: 2.6rem;
      right: -100%;
      width: 65%;
      height: 100%;
      z-index: 1001;
      box-shadow: -5px 5px 10px #333;
      transition: all 0.4s ease;
      ul {
        margin: auto;
        text-align: center;
        li {
          position: relative;
          width: 100%;
          line-height: 1.8rem;
          border-bottom: 1px #e5e5e5 solid;
          box-sizing: border-box;
          color: #222;
          text-align: left;
          padding: 0 0.6rem;
          font-size: 0.7rem;
          .current_div {
            transition: all 0.3s ease;
            div {
              transition: all ease 0.5s;
            }
          }
          i {
            border: solid #222;
            display: inline-block;
            border-width: 0 1px 1px 0;
            padding: 0.16rem;
            transform: rotate(-45deg);
            position: absolute;
            right: 0.6rem;
            top: 0.52rem;
          }
        }
      }
      .list_menu_fot {
        text-align: center;
        margin-top: 1rem;
        div {
          font-size: 0.8rem;
          color: #999;
          margin-bottom: 0.5rem;
        }
        a {
          span {
            font-size: 1rem;
            color: #d51419;
            display: block;
          }
        }
      }
    }
    .body2_list_bg {
      height: 100% !important;
      z-index: 1000;
      background: rgba(0, 0, 0, 0.9);
      width: 100% !important;
      position: fixed;
      top: 2.6rem;
      left: 0;
      right: 0;
      overflow: hidden;
    }
  }
}
</style>
