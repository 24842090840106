// axios请求
import axios from 'axios'

const instance = axios.create({
    baseURL: '/', // api 的 base_url
    withCredentials: true, //跨域时使用凭证，默认带上cookies
    timeout: 5000, // request timeout  设置请求超时时间
})

instance.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json'
        config.headers['Accept-Language'] = 'zh-CN'
        if (config.method === 'post') {
            if (!config.data) { // 没有参数时，config.data为null，需要转下类型
                config.data = {}
            }
            // config.data = JSON.stringify(config.data) // qs序列化参数
        }
        return config;
    },
);

instance.interceptors.response.use(
    res => {
        // Token 过期失效
        if (res.data.code === 402) {
            console.log('Token 过期失效');
        }
        return res
    }
)
export default instance