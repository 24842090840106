<template>
  <div class="index-quickly">
    <div class="pc-quickly">
      <ul>
        <li
          v-for="(item, idx) in currentData.quicklyList"
          :key="idx"
          @click="onjump(item)"
          @mouseenter="floatLi(item, idx)"
          @mouseleave="closeLi(item)"
        >
          <div>
            <i
              v-if="active == idx"
              :style="{
                backgroundImage:
                  'url(' + currentData.quicklyList2[idx].value + ')'
              }"
            ></i>
            <i
              v-else
              :style="{
                backgroundImage: 'url(' + item.value + ')'
              }"
            ></i>
            <span>{{ item.key }}</span>
          </div>
        </li>
        <div v-show="phone_show" class="phone_div">
          客服电话：{{ currentData.phone }}
        </div>
        <div v-show="data_show" class="data_div">
          <img :src="currentData.hover_ewm" style="width: 100%" alt="二维码" />
        </div>
      </ul>
    </div>
    <div class="mobile_quickly">
      <a
        v-for="(item, idx) in currentData.sjList"
        :key="idx"
        :href="idx == 0 ? 'tel:' + currentData.phone : currentData.link"
        class=""
      >
        <img :src="item.value" alt="" />
        <span>{{ item.key }}</span>
      </a>
    </div>
    <div class="quickly-img" v-if="top_show" @click="fanhuiTop">
      <img
        :src="currentData.fh_top_ico"
        alt=""
        style="width:100%;height:100%"
      />
    </div>
    <!-- <a
      class="quickly-ico"
      :href="currentData.link"
      target="_blank"
      :style="{
        backgroundImage: 'url(' + currentData.pc_ico + ')'
      }"
    ></a>
    <a
      class="shoji-ico"
      :href="currentData.link"
      target="_blank"
      :style="{
        backgroundImage: 'url(' + currentData.sj_ico + ')'
      }"
    ></a> -->
  </div>
</template>

<script>
export default {
  props: {
    currentData: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      active: -1,
      phone_show: false,
      data_show: false,
      top_show: false
    }
  },
  methods: {
    handleScroll () {
      let top = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageYOffset
      )
      if (top > 600) {
        this.top_show = true
      } else {
        this.top_show = false
      }
    },
    onjump (item) {
      if (item.key == 'QQ咨询') {
        window.open(this.currentData.qq_link, '_blank')
      } else if (item.key == '在线咨询') {
        window.open(this.currentData.link, '_blank')
      } else if (item.key == '返回顶部') {
        this.fanhuiTop(this.changeData())
      }
    },
    fanhuiTop (i) {
      $('html,body').animate({ scrollTop: 0 }, 800)
      this.$emit('onFanhuiTop')
    },
    floatLi (item, i) {
      this.active = i
      if (item.key == '电话咨询') {
        this.phone_show = true
      } else if (item.key == '微信咨询') {
        this.data_show = true
      }
    },
    closeLi (item) {
      this.active = -1
      if (item.key == '电话咨询') {
        this.phone_show = false
      } else if (item.key == '微信咨询') {
        this.data_show = false
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  }
}
</script>

<style lang="less">
@import url('~@/assets/css/mixins.less'); //混合
.index-quickly {
  width: 100%;
  height: 100%;
  .pc-quickly {
    position: fixed;
    width: 68px;
    right: 0;
    top: 55%;
    margin-top: -200px;
    z-index: 1000;
    border-bottom: 0;
    ul {
      position: relative;
      background: #ffffff;
      border: 1px solid #e0e0e0;
      li {
        width: 68px;
        height: 68px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        border-bottom: 1px solid #e0e0e0;
        color: #333;
        font-size: 13px;
        text-align: center;
        transition: all 0.3s;
        cursor: pointer;
        i {
          height: 25px;
          margin-bottom: 10px;
          display: block;
          overflow: hidden;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: auto 25px;
          transition: all 0.3s;
        }
      }
      li:hover {
        background: #e62022;
        color: #fff;
      }
      li:nth-last-child(3) {
        background: #e62022;
        color: #fff;
      }
      .phone_div,
      .data_div {
        position: absolute;
        width: auto;
        height: auto;
        overflow: auto;
        background-color: #fff;
        transform-origin: center right;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 30%);
        word-break: break-all;
        border-radius: 4px;
        border: 1px solid #ebeef5;
        font-size: 16px;
      }
      .phone_div {
        top: 215px;
        left: -243px;
        padding: 18px 20px;
        min-width: 190px;
      }
      .data_div {
        width: 180px;
        top: 69px;
        left: -195px;
        p {
          margin-top: 5px;
          font-size: 12px;
          color: #000;
        }
      }
    }
  }
  .mobile_quickly {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: #e62022;
    height: 2.6rem;
    z-index: 99;
    border-top: 1px solid #e7e7e7;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    color: #fff;
    font-size: 0.8rem;
    display: none;
    img {
      width: 1.2rem;
      margin-right: 0.5rem;
      vertical-align: sub;
    }
    a:nth-child(1)::after {
      content: '';
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translateY(-50%);
      width: 0.08rem;
      height: 1rem;
      background: #ff797a;
    }
  }
  .quickly-img {
    display: none;
    width: 2rem;
    position: fixed;
    right: 0.5rem;
    bottom: 5rem;
    z-index: 9;
    opacity: 0.7;
  }
  .quickly-ico,
  .shoji-ico {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    position: fixed;
    overflow: hidden;
    cursor: pointer;
  }
  .quickly-ico {
    width: 44px;
    height: 98px;
    bottom: 2px;
    right: 0;
    border-radius: 5px;
  }
  .shoji-ico {
    display: none;
    width: 3rem;
    height: 4rem;
    right: 0.3rem;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
  }
}
</style>
