import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/js/rem.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "swiper/css/swiper.css"
import Carousel from "@/common/Carousel/Carousel"
// 引入jquery
import '@/assets/js/jquery-1.8.3.min'
import '@/assets/js/jquery.superslide'
import AOS from 'aos'
import 'aos/dist/aos.css'
import axios from 'axios'
Vue.component("Carousel", Carousel)
Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.changeData = function() {
    let curl = window.location.href // 发布使用
        // let curl = "http://192.168.2.3/singlewebsite/cy_hjh/#/" // 本地使用
    let curls = curl.split('singlewebsite/')
    if (curls.length > 1) {
        curl = curls[1]
        curl = curl.split('/')[0]
        return curl
    } else {
        axios.get('/addons/singlewebsite/config/base')
            .then(res => {
                // 处理响应数据
                if (res.data.code == 1) {
                    return res.data.data.project_code
                } else {
                    console.error(res);
                }
            })
            .catch(error => {
                // 处理错误情况
                console.error(error);
            });
    }
}

Vue.prototype.H5_IMG_HOST = '' // URL前缀 发布使用
    // Vue.prototype.H5_IMG_HOST = `http://192.168.2.3/` // URL前缀 本地使用
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')