<template>
  <div class="swiper-container" ref="cur" v-if="status == 0">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, i) in list" :key="i">
        <img
          :src="item.pc_images"
          style="width:100%;height:100%;"
        />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
  </div>
  <div class="swiper-container swiper2" ref="cur" v-else-if="status == 1">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(item, idx) in list" :key="idx">
        <ul class="slide-list">
          <li v-for="(el, i) in item.dataList" :key="i">
            <template v-if="i == 0 || i == 2 || i == 4">
              <div class="li_p">{{ el.value }}</div>
              <div class="li_bian">
                <img src="@/assets/image/gywm/bian_9.png" alt="" />
              </div>
              <div class="li_date">
                <div class="icon centerText">
                  <span></span>
                  <h1></h1>
                  <h2 v-if="el.key">{{ el.key.slice(0, 4) }}</h2>
                  <h3 v-if="el.key">{{ el.key.slice(5) }}</h3>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="li_date2">
                <div class="icon centerText">
                  <span></span>
                  <h1></h1>
                  <h2 v-if="el.key">{{ el.key.slice(0, 4) }}</h2>
                  <h3 v-if="el.key">{{ el.key.slice(5) }}</h3>
                </div>
              </div>
              <div class="li_bian">
                <img src="@/assets/image/gywm/bian_9.png" alt="" />
              </div>
              <div class="li_p2">{{ el.value }}</div>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="swiper-container" ref="cur" v-else-if="status == 2">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(carousel, idx) in list" :key="idx">
        <img :src="carousel" style="width:100%;height:100%;" />
      </div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
// 引入Swiper
import Swiper from 'swiper'
export default {
  name: 'Carousel',
  props: {
    list: {
      type: Array,
      default: []
    },
    status: {
      type: Number,
      default: 0
    }
  },
  watch: {
    list: {
      //为什么即使监听到数据变化了，还需要放入$nextTick？因为v-for渲染需要时间，在没有渲染完成之前，不能初始化swiper
      immediate: true,
      handler () {
        //只能监听到数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
        this.$nextTick(() => {
          var mySwiper = new Swiper(this.$refs.cur, this.data)
        })
      }
    }
  },
  data () {
    return {
      data: {
        direction: 'horizontal' /*横向滑动*/,
        loop: this.list.length > 10,
        autoplay: {
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay （没有加入之前轮播图设置 autoplay：true无效）
          delay: 5000 // 自动切换的时间间隔（单位ms）
        },
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          //点击小球的时候也切换图片
          clickable: true,
          //修改分页器样式 ,
          bulletClass: 'my-swiper-pagination-bullet',
          //激活样式
          bulletActiveClass: 'my-bullet-active'
        },
        observeParents: false, //必须加入
        observer: true //必须加入
      }
    }
  },
  methods: {},
  mounted () {
    if (this.status != 0 && this.status != 2) {
      this.data.autoplay = false
    }
  }
}
</script>
<style scoped lang="less">
.slide-list {
  width: 86%;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  outline: none;
  display: flex;
  li {
    width: 25%;
    .li_p,
    .li_p2 {
      padding: 0 24px;
      height: 120px;
      font-size: 15px;
      color: #666666;
      line-height: 24px;
      opacity: 0.9;
      overflow: hidden;
    }
    .li_bian {
      width: 100%;
      margin-top: 18px;
      img {
        width: 98%;
        margin: auto;
      }
    }
    .li_date,
    .li_date2 {
      width: 100%;
      height: 132px;
      padding-top: 60px;
      position: relative;
      text-align: center;
      .icon {
        position: relative;
        left: calc(50% - 37px);
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #e52123;
        text-align: center;
        flex-direction: column;
        color: #fff;
        span {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          border: solid 2px #f5f5f5;
          opacity: 0;
          transition: all 0.2s ease-out 0s;
          border-radius: 50%;
        }
        h1 {
          display: block;
          width: 2px;
          height: 60px;
          background: #e52123;
          position: absolute;
          left: 50%;
          top: -60px;
          margin-left: -1px;
        }
        h2 {
          font-family: 'OPENSANS-BOLD';
          font-size: 22px;
          line-height: 1.5;
        }
      }
    }
    .li_date2 {
      width: 100%;
      height: 120px;
      padding-top: 0;
      padding-bottom: 0;
      .icon {
        h1 {
          top: auto;
          bottom: -68px;
          height: 68px;
        }
      }
    }
    .li_p2 {
      margin-top: 24px;
    }
  }
  li:hover {
    .li_date {
      .icon span {
        opacity: 1;
        width: 88%;
        height: 88%;
        left: 3%;
        top: 3%;
      }
    }
  }
}
/deep/.swiper-slide {
  // min-height: 464px;
  display: flex;
  flex-wrap: wrap;
}
.swiper-slide-img {
  width: 50%;
}
/deep/.swiper-container {
  position: relative;
}
.swiper-pagination {
  min-width: 1200px;
  display: flex;
  justify-content: center;
}
/deep/.swiper-pagination-bullets {
  bottom: 20px;
}
/deep/.my-swiper-pagination-bullet {
  width: 36px;
  height: 4px;
  opacity: 0.4;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 5px;
}
/deep/.my-bullet-active {
  background: #fff;
  cursor: pointer;
  opacity: 1;
}
/deep/.swiper-container-horizontal > .swiper-pagination-bullets {
  left: 0;
  width: 100%;
}
</style>
